import RainbowLink from '@otovo/shared/components/RainbowLink/RainbowLink';
import { useGlobalConfig } from '@otovo/shared/hooks/configContext';

import LegalLinks from '@otovo/shared/components/LegalLinks';
import SocialMediaLinks from '@otovo/shared/components/SocialMediaLinks/SocialMediaLinks';
import OtovoLogoWhite from '../../../illustrations/otovo-logo-white.svg';
import { getLinks } from './Links';

const CustomFooter = () => {
  const { STOREFRONT_URL } = useGlobalConfig();
  return (
    <div className="bg-grey-90 text-lilac-20 w-full">
      <div className="mx-auto w-full max-w-7xl px-8 py-11 sm:px-16">
        <div className="grid w-full grid-cols-1 gap-4 pb-6 pt-12 md:grid-cols-2 lg:grid-cols-4">
          <OtovoLogoWhite className="h-24 md:col-span-2" />
          {getLinks(STOREFRONT_URL).map((section, i) => {
            return (
              <>
                <div className="w-full py-2 lg:col-span-1" key={section.title}>
                  <h2 className="text-lilac-20 text-xl font-medium">
                    {section.title}
                  </h2>
                  {section.sublinks.map((sublink) => {
                    return (
                      <div key={sublink.href} className="pb-1 pt-1">
                        <RainbowLink
                          href={sublink.href}
                          className="text-lilac-20 font-normal"
                        >
                          {sublink.title}
                        </RainbowLink>
                      </div>
                    );
                  })}
                </div>
                {(i - 1) % 2 === 0 && (
                  <div className="hidden lg:col-span-2 lg:block" />
                )}
              </>
            );
          })}
          <div className="hidden lg:col-span-2 lg:block" />
          <div className="my-4 flex items-center md:col-span-1 lg:col-span-3">
            <LegalLinks />
          </div>
          <div className="my-4 flex items-center md:col-span-1 lg:col-span-1">
            <SocialMediaLinks type="light" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomFooter;
